import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'; //npm install --save react-router-dom
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import jsSHA from "jssha"; // npm install jssha
import { getRequestParams } from '../helpers/globalfunctions.js';
export class ResetPasswordComponent extends Component {
    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        this.state = {
            isSubmitted: false,
            loadPanelVisible: false,
            isValidPassword: false,
            isPasswordUpdated: false,
            requestId: parsed.requestId ?? '',
            isValidRequestId: false,
            ticketUserId: 0,
            userName: '',
            oldPassword: '',
            isRequestUsed: false,
            password: '',
            confirmPasswrod: '',
            pwdType: 'password',
            confirmPwdType: 'password',
            errors: {
                password: '',
                confirmPasswrod: '',
            }
        }
    }
    async componentDidMount() {
        await this.getResetPasswordUserCredential();
    }
    getResetPasswordUserCredential = async () => {
        try {
            if (this.state.requestId !== null && this.state.requestId.trim().length > 0) {
                this.setState({ isValidRequestId: true });
            }
            else {
                this.setState({ isValidRequestId: false });
            }
            this.setState({ loadPanelVisible: true });
            if (this.state.requestId.trim() !== '') {
                let request = {};
                request.RequestId = this.state.requestId;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('authentication/get-reset-password-usercredential', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    if (data.isSuccess) {
                        this.setState({
                            ticketUserId: data.userId,
                            userName: data.userName,
                            oldPassword: data.password,
                            isRequestUsed: data.isUsed
                        });
                    }
                    else {
                        this.setState({ isValidRequestId: false });
                        await this.showMessage('error', null, data.resultMessage);
                    }
                }
                else {
                    this.setState({ isValidRequestId: false });
                    await this.showMessage('error', null, null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'password':
                    errors.password = '';
                    if (value.length < 20) {
                        this.setState({ password: value });
                        await this.isValidPasswordInput(value);
                    }
                    break;
                case 'confirmPasswrod':
                    errors.confirmPasswrod = '';
                    if (value.length < 20) {
                        this.setState({ confirmPasswrod: value });
                        await this.isValidPasswordInput(value);
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    isValidPasswordInput = async (password) => {
        let isValid = false;
        try {
            var elementpwdlength = document.getElementById("pwdlength");
            var elementpwdlowercase = document.getElementById("pwdlowercase");
            var elementpwduppercase = document.getElementById("pwduppercase");
            var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
            var elementspecialcharacters = document.getElementById("specialcharacters");

            const renumbersymbolexp = /[0-9]/;
            const lowercaseexp = /[a-z]/;
            const uppercaseexp = /[A-Z]/;
            const specialcharacters = /[!@#$%^&*]/;

            var IsPwdnumbersymbol = renumbersymbolexp.test(password);
            var IsLowercase = lowercaseexp.test(password);
            var IsUppercase = uppercaseexp.test(password);
            var IsSpecialCharacters = specialcharacters.test(password);

            if (password.length > 8) {
                elementpwdlength.classList.remove("text-muted");
                elementpwdlength.classList.add("text-success");
            }
            else {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
            }

            if (IsPwdnumbersymbol) {
                elementpwdnumbersymbol.classList.remove("text-muted");
                elementpwdnumbersymbol.classList.add("text-success");
            }
            else {
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
            }

            if (IsLowercase) {
                elementpwdlowercase.classList.remove("text-muted");
                elementpwdlowercase.classList.add("text-success");
            }
            else {
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
            }

            if (IsUppercase) {
                elementpwduppercase.classList.remove("text-muted");
                elementpwduppercase.classList.add("text-success");
            }
            else {
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
            }

            if (IsSpecialCharacters) {
                elementspecialcharacters.classList.remove("text-muted");
                elementspecialcharacters.classList.add("text-success");
            }
            else {
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (password === "") {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (password.length > 8 && IsPwdnumbersymbol === true && IsLowercase === true && IsUppercase === true && IsPwdnumbersymbol === true && IsSpecialCharacters === true) {
                this.setState({ isValidPassword: true, isValid: true });
            } else {
                this.setState({ isValidPassword: false, isValid: false });
            }
        } catch (ex) {
            console.log(ex);
        }
        return isValid;
    }
    handleOnPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                pwdType: this.state.pwdType === 'input' ? 'password' : 'input',
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnConfirmPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                confirmPwdType: this.state.confirmPwdType === 'input' ? 'password' : 'input'
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    calcHash(value, securityKey) {
        let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update(value);
        let saltText = shaObj.getHash("HEX");
        let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj2.update(saltText + securityKey);
        return shaObj2.getHash("HEX");
    }
    handleOnResetPasswordSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                const tokenRequestParams = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ UserName: this.state.userName })
                }
                const tokenResponse = await fetch('authentication/password-token-request', tokenRequestParams);
                if (tokenResponse.ok) {
                    const tokenData = await tokenResponse.json();
                    if (tokenData.isSuccess === true) {
                        let request = {};
                        request.Password = this.state.password;
                        request.Email = '';
                        request.RequestId = this.state.requestId;
                        request.TicketUserId = Number(this.state.ticketUserId);
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('authentication/reset-user-account-password', requestParams);
                        const data = await response.json();
                        if (data.isSuccess) {
                            this.setState({ isPasswordUpdated: true });
                            let successMsg = (data.resultMessage === '' || data.resultMessage === null) ? 'This user password has been reset successfully.' : data.resultMessage;
                            await Swal.fire({
                                allowOutsideClick: false,
                                icon: 'success',
                                title: 'Success',
                                html: successMsg,
                            }).then(async (result) => {
                                if (data.isSuccess) {
                                    if (result.isConfirmed) {
                                        sessionStorage.clear();
                                        window.location.href = "/";
                                    }
                                }
                            });
                        }
                        else {
                            await this.showMessage('error', null, null);
                        }
                    }
                    else {
                        await this.showMessage('error', 'User authentication failed!', 'Faild to authenticate user ' + this.state.userName);
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.password = '';
            errors.confirmPasswrod = '';
            if (this.state.password.trim() === '') {
                isValid = false;
                errors.password = "Please enter password.";
            }
            if (this.state.confirmPasswrod.trim() === '') {
                isValid = false;
                errors.confirmPasswrod = "Please re-enter password.";
            }
            if (this.state.password.trim() !== '' && this.state.confirmPasswrod.trim() !== '') {
                if (this.state.password.trim() !== this.state.confirmPasswrod.trim()) {
                    errors.confirmPasswrod = 'Password does not match, Re-enter password again.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    showMessage = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-5">
                            <div classname="captionsidebar">
                                <h3 className="captionsidebarheader">Get Your Tickets Online</h3>
                                <p className="captionsidebarcontent">Get Onboard Quickly: Book Train & Bus Tickets Online<br />For Check Your Tickit Status
                                    <NavLink tag={Link} className="linktxt p-1" title="Check tickit status" to="/ticketing/ticket/status">Click here</NavLink>
                                    <br /><br />
                                    <NavLink tag={Link} className="btn loginbtn p-2" to="/ticketing/ticket/create">Open a New Ticket</NavLink>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="card">
                                <div className="card-body login-card-body">
                                    <div className="forgot-password-logo">
                                        <NavLink><img src="../../dist/img/logo_utwiz_dark.png" alt="UTWiz Ticketing" /></NavLink>
                                    </div>
                                    <h4 className="text-center"><strong>Reset your password</strong></h4>
                                    <p className="text-center" style={{ display: (this.state.isRequestUsed) || 'none' }}>
                                        <span className='text-sm text-danger font-weight-bold'>{'The password is already reset'}</span>&nbsp;&nbsp;
                                        <a href="/login" title="Sign In">Sign In</a>
                                    </p>
                                    <p className="text-center" style={{ display: (!this.state.isValidRequestId) || 'none' }}>
                                        <span className='text-sm text-danger font-weight-bold'>{'The request is not valid'}</span>
                                    </p>
                                    <form method="post" onSubmit={this.handleOnResetPasswordSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label>New Password <span className="text-red">*</span></label>
                                                    <div className="input-group">
                                                        <div class="input-group-prepend">
                                                            <span className="input-group-text p-0 pl-2 pr-2"><i className="fas fa-lock iconpass"></i></span>
                                                        </div>
                                                        <input type={this.state.pwdType}
                                                            className="form-control form-control-sm"
                                                            name="password"
                                                            title="New Password"
                                                            placeholder="Enter new password"
                                                            disabled={(this.state.isRequestUsed || !this.state.isValidRequestId)}
                                                            value={this.state.password}
                                                            onChange={this.handleOnInputChange} />
                                                        {this.state.errors["password"].length > 0 && <span className="error invalid-feedback">{this.state.errors["password"]}</span>}
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text p-0">
                                                                <button onClick={this.handleOnPwdShowHide} className="btn button-default p-0 pl-1 pr-1">{this.state.pwdType === 'input' ? (<i className="fas fa-eye-slash iconpass"></i>)
                                                                    : (<i className="fas fa-eye iconpass"></i>)}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label>Re-type New Password <span className="text-red">*</span></label>
                                                    <div className="input-group">
                                                        <div class="input-group-prepend">
                                                            <span className="input-group-text p-0 pl-2 pr-2"><i className="fas fa-lock iconpass"></i></span>
                                                        </div>
                                                        <input type={this.state.confirmPwdType}
                                                            className="form-control form-control-sm"
                                                            name="confirmPasswrod"
                                                            title="Re-type New Password"
                                                            placeholder="Re enter new password"
                                                            disabled={(this.state.isRequestUsed || !this.state.isValidRequestId)}
                                                            value={this.state.confirmPasswrod}
                                                            onChange={this.handleOnInputChange} />
                                                        {this.state.errors["confirmPasswrod"].length > 0 && <span className="error invalid-feedback">{this.state.errors["confirmPasswrod"]}</span>}
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text p-0">
                                                                <button onClick={this.handleOnConfirmPwdShowHide} className="btn button-default p-0 pl-1 pr-1">{this.state.confirmPwdType === 'input' ? (<i className="fas fa-eye-slash iconpass"></i>)
                                                                    : (<i className="fas fa-eye iconpass"></i>)}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div className="form-group">
                                                    <p className="mb-0"><small id="pwdlength" className="text-muted"><i className="fas fa-check-circle"></i> At least 8 characters long</small></p>
                                                    <p className="mb-0"><small id="pwdlowercase" className="text-muted"><i className="fas fa-check-circle"></i> One lowercase character</small></p>
                                                    <p className="mb-0"><small id="pwduppercase" className="text-muted"><i className="fas fa-check-circle"></i> One uppercase character</small></p>
                                                    <p className="mb-0"><small id="pwdnumbersymbol" className="text-muted"><i className="fas fa-check-circle"></i> One number, symbol, or whitespace character</small></p>
                                                    <p className="mb-0"><small id="specialcharacters" className="text-muted"><i className="fas fa-check-circle"></i> One special character</small></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                <button type="submit" className="btn btn-success btn-block"
                                                    title="Reset your password"
                                                    disabled={((!this.state.isValidPassword) || this.state.isPasswordUpdated || this.state.isRequestUsed || (!this.state.isValidRequestId))}>
                                                    {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isSubmitted && <span title="Reset your password"><i className="fas fa-key"></i> Reset your password</span>}
                                                </button>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" >
                                                <NavLink className="text-center" tag={Link} to="/" title="Go back to login"><i className="mb-0 text-center"></i>Go back to login</NavLink>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}