import React, { Component } from 'react';
import NumberFormat from 'react-number-format'; //npm install react-number-format --save
import { getRequestParams } from '../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
import { NavLink } from 'reactstrap';
export class AccountRegistrationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isUserVerifySubmitted: false,
            isValidEmailAddress: false,
            isValidPassword: false,
            userEmail: '',
            userFirstName: '',
            userLastName: '',
            userMobile: '',
            countryCode: '',
            password: '',
            confirmPasswrod: '',
            pwdType: 'password',
            confirmPwdType: 'password',
            errors: {
                userEmail: '',
                userFirstName: '',
                userMobile: '',
                countryCode: '',
                password: '',
                confirmPasswrod: '',
            }
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'userEmail':
                    errors.userEmail = '';
                    if (value.length < 51) {
                        this.setState({ userEmail: value, isValidEmailAddress: false })
                    }
                    let regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (regEmail.test(String(value.trim()).toLowerCase())) {
                        this.setState({ isValidEmailAddress: true });
                    }
                    break;
                case 'userFirstName':
                    errors.userFirstName = '';
                    if (value.length <= 50) {
                        this.setState({ userFirstName: value });
                    }
                    break;
                case 'userLastName':
                    errors.userLastName = '';
                    if (value.length <= 50) {
                        this.setState({ userLastName: value });
                    }
                    break;
                case 'password':
                    errors.password = '';
                    if (value.length < 20) {
                        this.setState({ password: value });
                        await this.isValidPasswordInput(value);
                    }
                    break;
                case 'confirmPasswrod':
                    errors.confirmPasswrod = '';
                    if (value.length < 20) {
                        this.setState({ confirmPasswrod: value });
                        await this.isValidPasswordInput(value);
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputLeave = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            if (name === 'userEmail') {
                let errors = this.state.errors;
                errors.userEmail = '';
                let userEmail = this.state.userEmail.trim();
                let regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regEmail.test(String(userEmail.trim()).toLowerCase())) {
                    this.setState({ isUserVerifySubmitted: true });
                    let request = {};
                    request.Email = userEmail;
                    request.Password = '';
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('authentication/verify-user-account', requestParams);
                    const data = await response.json();
                    if (data.isSuccess) {
                        errors.userEmail = userEmail + ' account already exists';
                    }
                }
                this.setState({ errors: errors });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isUserVerifySubmitted: false });
    }
    isValidPasswordInput = async (password) => {
        let isValid = false;
        try {
            var elementpwdlength = document.getElementById("pwdlength");
            var elementpwdlowercase = document.getElementById("pwdlowercase");
            var elementpwduppercase = document.getElementById("pwduppercase");
            var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
            var elementspecialcharacters = document.getElementById("specialcharacters");

            const renumbersymbolexp = /[0-9]/;
            const lowercaseexp = /[a-z]/;
            const uppercaseexp = /[A-Z]/;
            const specialcharacters = /[!@#$%^&*]/;

            var IsPwdnumbersymbol = renumbersymbolexp.test(password);
            var IsLowercase = lowercaseexp.test(password);
            var IsUppercase = uppercaseexp.test(password);
            var IsSpecialCharacters = specialcharacters.test(password);

            if (password.length > 8) {
                elementpwdlength.classList.remove("text-muted");
                elementpwdlength.classList.add("text-success");
            }
            else {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
            }

            if (IsPwdnumbersymbol) {
                elementpwdnumbersymbol.classList.remove("text-muted");
                elementpwdnumbersymbol.classList.add("text-success");
            }
            else {
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
            }

            if (IsLowercase) {
                elementpwdlowercase.classList.remove("text-muted");
                elementpwdlowercase.classList.add("text-success");
            }
            else {
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
            }

            if (IsUppercase) {
                elementpwduppercase.classList.remove("text-muted");
                elementpwduppercase.classList.add("text-success");
            }
            else {
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
            }

            if (IsSpecialCharacters) {
                elementspecialcharacters.classList.remove("text-muted");
                elementspecialcharacters.classList.add("text-success");
            }
            else {
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (password === "") {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (password.length > 8 && IsPwdnumbersymbol === true && IsLowercase === true && IsUppercase === true && IsPwdnumbersymbol === true && IsSpecialCharacters === true) {
                this.setState({ isValidPassword: true, isValid: true });
            } else {
                this.setState({ isValidPassword: false, isValid: false });
            }
        } catch (ex) {
            this.setState({ isValidPassword: false });
            console.log(ex);
        }
        return isValid;
    }
    handleOnPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                pwdType: this.state.pwdType === 'input' ? 'password' : 'input',
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnConfirmPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                confirmPwdType: this.state.confirmPwdType === 'input' ? 'password' : 'input'
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAccountRegistrationSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let request = {};
                request.FirstName = this.state.userFirstName.trim();
                request.LastName = this.state.userLastName.trim();
                request.Password = this.state.password;
                request.Email = this.state.userEmail.trim();
                request.Mobile = this.state.userMobile.trim();
                request.CountryCode = this.state.countryCode.trim();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('authentication/account-register', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        html: data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                            }
                        }
                    })
                }
                else {
                    await this.showMessage('error', 'Failed!', null);
                }
            }
        } catch (ex) {
            await this.showMessage('error', 'Failed!', null);
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    handleOnReset = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isUserVerifySubmitted: false,
                isValidEmailAddress: false,
                isValidPassword: false,
                userEmail: '',
                userFirstName: '',
                userLastName: '',
                userMobile: '',
                countryCode: '',
                password: '',
                confirmPasswrod: '',
                pwdType: 'password',
                confirmPwdType: 'password',
                errors: {
                    userEmail: '',
                    userFirstName: '',
                    userMobile: '',
                    countryCode: '',
                    password: '',
                    confirmPasswrod: '',
                }
            });
            var elementpwdlength = document.getElementById("pwdlength");
            var elementpwdlowercase = document.getElementById("pwdlowercase");
            var elementpwduppercase = document.getElementById("pwduppercase");
            var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
            var elementspecialcharacters = document.getElementById("specialcharacters");
            elementpwdlength.classList.remove("text-success");
            elementpwdlength.classList.add("text-muted");
            elementpwdnumbersymbol.classList.remove("text-success");
            elementpwdnumbersymbol.classList.add("text-muted");
            elementpwdlowercase.classList.remove("text-success");
            elementpwdlowercase.classList.add("text-muted");
            elementpwduppercase.classList.remove("text-success");
            elementpwduppercase.classList.add("text-muted");
            elementspecialcharacters.classList.remove("text-success");
            elementspecialcharacters.classList.add("text-muted");
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.userEmail = '';
            errors.userFirstName = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.password = '';
            errors.confirmPasswrod = '';
            let regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.state.userEmail.trim() === '') {
                isValid = false;
                errors.userEmail = "Please enter email.";
            }
            if (this.state.userEmail.trim() !== '') {
                if (!regEmail.test(String(this.state.userEmail.trim()).toLowerCase())) {
                    isValid = false;
                    errors.userEmail = "Please enter valid email.";
                }
            }
            if (this.state.userFirstName.trim() === '') {
                errors.userFirstName = 'Please enter first name.';
                isValid = false;
            }
            if (this.state.countryCode.trim() !== '') {
                if (this.state.userMobile.trim() === '') {
                    isValid = false;
                    errors.userMobile = 'Please enter mobile no.';
                }
            }
            if (this.state.userMobile.trim() !== '') {
                if (this.state.countryCode.trim() === '') {
                    isValid = false;
                    errors.countryCode = 'Please enter country code.';
                }
                if (this.state.userMobile.trim().length !== 10) {
                    isValid = false;
                    errors.userMobile = 'Please enter valid mobile no.';
                }
            }
            if (this.state.password.trim() === '') {
                isValid = false;
                errors.password = "Please enter password.";
            }
            if (this.state.confirmPasswrod.trim() === '') {
                isValid = false;
                errors.confirmPasswrod = "Please re-enter password.";
            }
            if (this.state.password.trim() !== '' && this.state.confirmPasswrod.trim() !== '') {
                if (this.state.password.trim() !== this.state.confirmPasswrod.trim()) {
                    errors.confirmPasswrod = 'Password not matched, Re-enter password again.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    showMessage = async (icon, title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 mt-5">
                            <div classname="captionsidebar">

                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <form method="post" autocomplete="off" onSubmit={this.handleOnAccountRegistrationSubmit}
                                style={(this.state.isSubmitted || this.state.isUserVerifySubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div className="card card-registration">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <div className="mb-2">
                                                <a href="/"><img src="../../dist/img/logo_utwiz_dark.png" alt="UTWiz Ticketing" /></a>
                                            </div>
                                            <p className="text-center text-lg text-green font-weight-bold mb-0">Account Registration</p>
                                            <p className="text-center mb-2">Use the forms below to create the information we have on file for your account.</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <div className="form-group">
                                                    <label>Email <span className="text-danger">*</span></label>
                                                    <input type="text" autocomplete="off" className="form-control form-control-sm" style={{ backgroundColor: (!this.state.isValidEmailAddress) || '#d4efd4' }}
                                                        name="userEmail"
                                                        placeholder="example@domain.com"
                                                        title="Email"
                                                        value={this.state.userEmail}
                                                        onChange={this.handleOnInputChange}
                                                        onBlur={this.handleOnInputLeave} />
                                                    {this.state.isUserVerifySubmitted && this.state.isValidEmailAddress
                                                        && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                            <i className="fas fa-spinner fa-spin"></i> </span>}
                                                    {this.state.errors["userEmail"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userEmail"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                <div className="form-row">
                                                    <div className="input-group col-md-4">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text pl-2 pr-2" id="basic-addon1"><i className="fas fa-phone-alt iconpass"></i></span>
                                                        </div>
                                                        <NumberFormat className="form-control form-control-sm" name="countryCode" title="Country Code" format="+##" value={this.state.countryCode} onValueChange={(e) => {
                                                            let err = this.state.errors;
                                                            err.countryCode = '';
                                                            this.setState({ countryCode: e.value, errors: err });
                                                        }} placeholder="" />
                                                        {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                                    </div>
                                                    <div className="input-group col-md-8">
                                                        <NumberFormat className="form-control form-control-sm" name="userMobile" title="Mobile Number"
                                                            placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.userMobile}
                                                            onValueChange={(e) => {
                                                                let err = this.state.errors;
                                                                err.userMobile = '';
                                                                this.setState({ userMobile: e.value, errors: err });
                                                            }} />
                                                        {this.state.errors["userMobile"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userMobile"]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                <div className="form-row">
                                                    <div className="input-group col-md-6">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text pl-2 pr-2" id="basic-addon1"><i className="fas fa-user iconpass"></i></span>
                                                        </div>
                                                        <input type="text" className="form-control form-control-sm"
                                                            name="userFirstName"
                                                            placeholder="First name"
                                                            title="First Name"
                                                            value={this.state.userFirstName}
                                                            onChange={this.handleOnInputChange} />
                                                        {this.state.errors["userFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userFirstName"]}</span>}
                                                    </div>
                                                    <div className="input-group col-md-6">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text pl-2 pr-2" id="basic-addon1"><i className="fas fa-user iconpass"></i></span>
                                                        </div>
                                                        <input type="text" className="form-control form-control-sm"
                                                            name="userLastName"
                                                            placeholder="Last name"
                                                            title="Last Name"
                                                            value={this.state.userLastName}
                                                            onChange={this.handleOnInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                <div className="form-row">
                                                    <div className="input-group col-md-6">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text pl-2 pr-2" id="basic-addon1"><i className="fas fa-lock iconpass"></i></span>
                                                        </div>
                                                        <input type={this.state.pwdType}
                                                            className="form-control form-control-sm"
                                                            autocomplete="off"
                                                            name="password"
                                                            placeholder="Password"
                                                            value={this.state.password}
                                                            onChange={this.handleOnInputChange} />
                                                        {this.state.errors["password"].length > 0 && <span className='error invalid-feedback'>
                                                            {this.state.errors["password"]}</span>}
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text p-0 pl-1 pr-1"><NavLink onClick={this.handleOnPwdShowHide}>{this.state.pwdType === 'input' ? (<i className="fas fa-eye-slash iconpass"></i>)
                                                                : (<i className="fas fa-eye iconpass"></i>
                                                                )}</NavLink></div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group col-md-6">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text pl-2 pr-2" id="basic-addon1"><i className="fas fa-lock iconpass"></i></span>
                                                        </div>
                                                        <input type={this.state.confirmPwdType}
                                                            className="form-control form-control-sm"
                                                            name="confirmPasswrod"
                                                            placeholder="Re-enter Password"
                                                            value={this.state.confirmPasswrod}
                                                            onChange={this.handleOnInputChange} />
                                                        {this.state.errors["confirmPasswrod"].length > 0 && <span className='error invalid-feedback'>
                                                            {this.state.errors["confirmPasswrod"]}</span>}
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text p-0 pl-1 pr-1">
                                                                <NavLink onClick={this.handleOnConfirmPwdShowHide}>{this.state.confirmPwdType === 'input' ? (<i className="fas fa-eye-slash iconpass"></i>)
                                                                    : (<i className="fas fa-eye iconpass"></i>)}</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-0">
                                                <div className="form-group">
                                                    <p className="mb-0"><small id="pwdlength" className="text-muted"><i className="fas fa-check-circle"></i> At least 8 characters long</small></p>
                                                    <p className="mb-0"><small id="pwdlowercase" className="text-muted"><i className="fas fa-check-circle"></i> One lowercase character</small></p>
                                                    <p className="mb-0"><small id="pwduppercase" className="text-muted"><i className="fas fa-check-circle"></i> One uppercase character</small></p>
                                                    <p className="mb-0"><small id="pwdnumbersymbol" className="text-muted"><i className="fas fa-check-circle"></i> One number, symbol, or whitespace character</small></p>
                                                    <p className="mb-0"><small id="specialcharacters" className="text-muted"><i className="fas fa-check-circle"></i> One special character</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <a href="/login" className="text-sm" title="I already have a membership">
                                                    <i className="fas fa-user"></i> I already have an account</a>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <button type="submit" className="btn btn-md btn-success btn-sm"
                                                    title="Sign up"
                                                    disabled={(!this.state.isValidPassword)}>
                                                    {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isSubmitted && <span title="Sign up"><i className="fas fa-sign-in-alt"></i> Sign up</span>}
                                                </button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-md btn-secondary btn-sm" title="Reset"
                                                    onClick={this.handleOnReset}>
                                                    <i className="fas fa-eraser"></i> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
