import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1  //npm install devextreme@21.1 devextreme-react@21.1 --save --save-exact
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'; //npm install --save react-router-dom
import { getRequestParams } from '../helpers/globalfunctions.js';
import Swal from 'sweetalert2'; //npm i sweetalert2
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import NumberFormat from 'react-number-format'; //npm install react-number-format --save
import Modal from 'react-modal'; //npm install --save react-modal
import { post } from 'axios'; // npm install --save axios
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];

export const priorityDropdownLis = [{
    'id': 1,
    'name': 'Normal',
}, {
    'id': 2,
    'name': 'Moderate',
}, {
    'id': 3,
    'name': 'High',
}]
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class TicketingComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isShowTicketClosedOnly: false,
            ticketDataList: [],
            ticketGridDataSource: null,
            isAllDateTicket: true,
            fromDate: moment(currentTime).add(-30, "days").format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            ticketNo: '',
            isValidTicketNo: false,
            isOpenAddNewTicketModal: false,
            isAddNewTicketSubmitted: false,
            helpTopicDropdownList: [],
            helpTopicDropdownData: null,
            helpTopicId: 0,
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            userMobile: '',
            countryCode: '',
            priorityId: 1,
            issueSummary: '',
            issueDetails: '',
            attachments: [],
            errors: {
                fromDate: '',
                toDate: '',
                ticketNo: '',
                helpTopic: '',
                userFirstName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                issueSummary: '',
                issueDetails: '',
            }
        }
    }
    async componentDidMount() {
        await this.getTicketList();
    }
    handleOnToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,
                    //text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "Tickets");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: function () {
                        e.component.refresh();
                    },
                    hint: 'Refresh'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField !== "id") {
            //debugger;
            //if (e.data.isReplyClosed === true) {
            //    if (e.data.issueStatusId === 2) {
            //        e.cellElement.style.backgroundColor = "#90EE90";
            //    }
            //    else {
            //        e.cellElement.style.backgroundColor = "#FF7F7F";
            //    }
            //}
            if (e.data.allowReply === false && e.data.isReplyClosed === false) {
                e.cellElement.style.color = "red";
            }
            else {
                if (e.data.issueStatusId === 2) {
                    e.cellElement.style.backgroundColor = "#c1ffcf";
                }
                else if (e.data.issueStatusId === 3) {
                    e.cellElement.style.backgroundColor = "#ffa9a9";
                }
                else {
                    e.cellElement.style.backgroundColor = "#fff";
                }
            }
        }
    }
    handleOnShowClosedFeedbck = (e) => {
        try {
            this.setState({ isShowTicketClosedOnly: e.target.checked });
            let ticketGridDataList = [...new Set(this.state.ticketDataList)];
            if (e.target.checked) {
                ticketGridDataList = [...new Set(this.state.ticketDataList.filter(item => item.isReplyClosed === true))];
            }
            this.setState({ ticketGridDataSource: ticketGridDataList });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                isShowTicketClosedOnly: false,
                ticketDataList: [],
                ticketGridDataSource: null,
                isAllDateTicket: true,
                fromDate: moment(currentTime).add(-30, "days").format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                ticketNo: '',
                isValidTicketNo: false,
                isOpenAddNewTicketModal: false,
                isAddNewTicketSubmitted: false,
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: {
                    fromDate: '',
                    toDate: '',
                    ticketNo: '',
                    helpTopic: '',
                    userFirstName: '',
                    userEmail: '',
                    userMobile: '',
                    countryCode: '',
                    issueSummary: '',
                    issueDetails: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            errors.ticketNo = '';
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            if (this.state.ticketNo.trim() !== '' && this.state.ticketNo.trim().length !== 8) {
                errors.ticketNo = 'Please enter 8 characters for each ticket.';
                isValid = false;
            }
            if (Number(sessionStorage.getItem('session_userId')) === 0) {
                isValid = false;
                await this.onfailure('error', 'Invalid User', 'User details not found');
            }
            this.setState({ errors: errors });
            if (isValid === true) {
                this.setState({ isSubmitted: true, loadPanelVisible: true });
                await this.getTicketList();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false, loadPanelVisible: false });
    }
    getTicketList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            let fromDate = '';
            let toDate = '';
            if (this.state.isAllDateTicket === false) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                }
            }
            let request = {};
            request.FromDate = fromDate;
            request.ToDate = toDate;
            request.TicketNo = (this.state.ticketNo.trim() !== '' ? 'TK' + this.state.ticketNo.trim() : '');
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('ticketing-api/list', requestParams);
            const data = await response.json();
            this.setState({
                ticketGridDataSource: data.tickets,
                ticketDataList: data.tickets
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                loadPanelVisible: false,
                isShowTicketClosedOnly: false,
                ticketDataList: [],
                ticketGridDataSource: null,
                isAllDateTicket: true,
                fromDate: moment(currentTime).add(-30, "days").format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                ticketNo: '',
                isValidTicketNo: false,
                isOpenAddNewTicketModal: false,
                isAddNewTicketSubmitted: false,
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: {
                    fromDate: '',
                    toDate: '',
                    ticketNo: '',
                    helpTopic: '',
                    userFirstName: '',
                    userEmail: '',
                    userMobile: '',
                    countryCode: '',
                    issueSummary: '',
                    issueDetails: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllDateTicket':
                    this.setState({ isAllDateTicket: value });
                    break;
                case 'ticketNo':
                    errors.ticketNo = '';
                    if (value.trim().length < 9) {
                        this.setState({ ticketNo: value.toUpperCase(), isValidTicketNo: false });
                        if (value.trim().length === 8) {
                            this.setState({ isValidTicketNo: true });
                        }
                    }
                    break;
                case 'userFirstName':
                    errors.userFirstName = '';
                    if (value.length <= 50) {
                        this.setState({ userFirstName: value });
                    }
                    break;
                case 'userLastName':
                    if (value.length <= 50) {
                        this.setState({ userLastName: value });
                    }
                    break;
                case 'userEmail':
                    errors.userEmail = '';
                    if (value.length <= 50) {
                        this.setState({ userEmail: value });
                    }
                    break;
                case 'issueSummary':
                    errors.issueSummary = '';
                    if (value.length < 76) {
                        this.setState({ issueSummary: value });
                    }
                    break;
                case 'ddhelptopic':
                    errors.helpTopic = '';
                    this.setState({ helpTopicId: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputLeave = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            if (name === 'ticketNo') {
                let ticketNo = this.state.ticketNo.trim();
                if (ticketNo.length === 8) {
                    this.setState({ isValidTicketNo: true });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPaste = async (event) => {
        try {
            let ticketNo = event.clipboardData.getData('Text').trim();
            if (ticketNo.length === 10) {
                ticketNo = ticketNo.substring(2, ticketNo.length);
                this.setState({ ticketNo: ticketNo, isValidTicketNo: true });
            }
            if (ticketNo.length === 8) {
                this.setState({ ticketNo: ticketNo, isValidTicketNo: true });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    // Start add new ticket
    getHelptopicDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('ticketing-api/helptopic-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                helpTopicDropdownList: data.helpTopics
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFileSelect = async (event) => {
        try {
            this.setState({ loadPanelVisible: true });
            let attachments = this.state.attachments;
            //let fileCount = attachments.length + event.target.files.length;
            //if (fileCount > 5) {
            //    await this.onfailure('error', 'Max file limit', 'Please select no more than 5 files.');
            //}
            //else
            if (event.target.files.length > 0) {
                let isFilesInvalid = false;
                let documentSelectedFile = event.target.files;
                const formData = new FormData();
                for (var i = 0; i < documentSelectedFile.length; i++) {
                    let file = documentSelectedFile[i];
                    if (file.size > 0) {
                        formData.append('File', file);
                    }
                    else {
                        isFilesInvalid = true;
                        await this.onfailure('error', 'Invalid file', 'The file ' + file.name + ' found invalid.');
                        break;
                    }
                }
                if (isFilesInvalid === false) {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'UserId': sessionStorage.getItem('session_userId'),
                            'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                            'CultureCode': sessionStorage.getItem('session_culturecode'),
                        },
                    };
                    const response = await post('ticketing-api/add-attachment', formData, config);
                    if (response.status === 200) {
                        const data = await response.data.attachments;
                        if (data !== null) {
                            let maxFileId = 0;
                            if (attachments !== null && attachments.length > 0) {
                                maxFileId = Math.max.apply(Math, attachments.map(function (val) { return val.id; }))
                            }
                            for (var a = 0; a < data.length; a++) {
                                maxFileId = Number(maxFileId) + 1;
                                let attachment = {};
                                attachment.id = maxFileId;
                                attachment.fileName = data[a].fileName;
                                attachment.fileData = data[a].fileData;
                                attachment.fileSize = data[a].fileSize;
                                attachment.fileExtension = data[a].fileExtension;
                                attachments.push(attachment);
                                this.setState({ attachments: attachments });
                            }
                        }
                    }
                }
            }
            else {
                await this.onfailure('error', 'Invalid file', 'Please select file again.');
            }
            this.fileInput.value = "";
        } catch (ex) {
            await this.onfailure('error', null, null);
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    removeAttachmante = async (e, id) => {
        e.preventDefault();
        try {
            let attachments = this.state.attachments;
            attachments = attachments.filter(r => r.id !== id);
            this.setState({ attachments: attachments });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOpenNewTicketModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getHelptopicDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleCloseNewTicketModal = async () => {
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            this.setState({
                isOpenAddNewTicketModal: false,
                isAddNewTicketSubmitted: false,
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSaveNewTicketSubmitted = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isAddNewTicketSubmitted: true });
                let request = {};
                request.HelpTopicId = Number(this.state.helpTopicId);
                request.UserFirstName = this.state.userFirstName.trim();
                request.UserLastName = this.state.userLastName.trim();
                request.UserEmail = this.state.userEmail.trim();
                request.UserMobile = this.state.userMobile.trim();
                request.CountryCode = this.state.countryCode.trim();
                request.IssuePriorityId = Number(this.state.priorityId);
                request.IssueSummary = this.state.issueSummary.trim();
                request.IssueDetails = this.state.issueDetails.trim();
                request.Attachments = this.state.attachments;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ticketing-api', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        html: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleCloseNewTicketModal();
                                await this.getTicketList();
                            }
                        }
                    })
                }
                else {
                    await this.onfailure('error', 'Failed!', null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isAddNewTicketSubmitted: false });
    }
    handleOnClearNewTicket = async (event) => {
        event.preventDefault();
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            this.setState({
                isAddNewTicketSubmitted: false,
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnHelpTopicDropdownChange = (data) => {
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            let helpTopicId = 0;
            if (data !== null) {
                helpTopicId = (data !== null ? data.id : 0);
            }
            else {
                //errors.helpTopic = 'Please select help topic.';
            }
            this.setState({ helpTopicDropdownData: data, helpTopicId: helpTopicId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnIssueBodyChange = (e) => {
        try {
            let errors = this.state.errors;
            errors.issueDetails = '';
            this.setState({ issueDetails: e.value, errors: errors })
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            let regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (Number(this.state.helpTopicId) === 0) {
                errors.helpTopic = 'Please select help topic.';
                isValid = false;
            }
            if (this.state.userFirstName.trim() === '') {
                errors.userFirstName = 'Please enter first name.';
                isValid = false;
            }
            if (this.state.userEmail.trim() === '') {
                isValid = false;
                errors.userEmail = "Please enter email.";
            }
            if (this.state.userEmail.trim() !== '') {
                if (!regEmail.test(String(this.state.userEmail.trim()).toLowerCase())) {
                    isValid = false;
                    errors.userEmail = "Please enter valid email.";
                }
            }
            if (this.state.countryCode.trim() !== '') {
                if (this.state.userMobile.trim() === '') {
                    isValid = false;
                    errors.userMobile = 'Please enter mobile no.';
                }
            }
            if (this.state.userMobile.trim() !== '') {
                if (this.state.countryCode.trim() === '') {
                    isValid = false;
                    errors.countryCode = 'Please enter country code.';
                }
                if (this.state.userMobile.trim().length !== 10) {
                    isValid = false;
                    errors.userMobile = 'Please enter valid mobile no.';
                }
            }
            if (this.state.issueSummary.trim() === '') {
                isValid = false;
                errors.issueSummary = "Please enter issue summary.";
            }
            //if (this.state.issueDetails.getEditorState().getCurrentContent().getPlainText().trim() === '') {
            if (this.removeHtmlTags(this.state.issueDetails.trim()).trim() === '') {
                isValid = false;
                errors.issueDetails = "Please enter issue details.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    removeHtmlTags = (str) => {
        try {
            if ((str === null) || (str === ''))
                return '';
            else
                str = str.toString();
            // Regular expression to identify HTML tags in 
            // the input string. Replacing the identified 
            // HTML tag with a null string.
            return str.replace(/(<([^>]+)>)/ig, '');
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }
    // End add new ticket

    // Start ticket view comment
    handleOnTicketViewComment = async (e, data) => {
        e.preventDefault();
        try {
            if (data.requestId === null || data.requestId === '') {
                await this.onfailure('error', 'Invalid request', null);
            }
            else {
                this.props.history.push({
                    pathname: '/ticketing/ticket/comment',
                    state: { requestId: data.requestId, allowReply: data.allowReply, isFromTicketWeb: true, allowClose: true, sourceUrl: "/ticketing/tickets" }
                })
                //window.location.href = "/ticketing/ticket/comment?requestId=" + data.requestId + "&allowReply=" + data.allowReply + "&allowClose=true";
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    // End ticket view comment
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        const renderGridCell = (data) => {
            return <div style={{ textAlign: 'center' }}>
                <NavLink title="View Ticket" onClick={(e) => { this.handleOnTicketViewComment(e, data.data) }} >
                    <i className="fas fa-eye"></i>
                </NavLink>
            </div>;
        };
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Ticketing</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={() => { this.setState({ isOpenAddNewTicketModal: true }) }}
                                            className="btn btn-block btn-outline-secondary btn-sm" title="Add Ticket"><i className="fas fa-plus"></i> Add Ticket</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllDateTicket"
                                                checked={this.state.isAllDateTicket}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDateTicket"> All Date (Ticket)</label>
                                        </div>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                            disabled={this.state.isAllDateTicket}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>To Date (Ticket)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                            disabled={this.state.isAllDateTicket}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Ticket No</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="fas fa-text iconpass">TK</i></span>
                                            </div>
                                            <input type="text" className="form-control form-textbox form-control-sm"
                                                name="ticketNo" title="Ticket No" placeholder="Enter ticket no"
                                                value={this.state.ticketNo}
                                                onChange={this.handleOnInputChange}
                                                onBlur={this.handleOnInputLeave}
                                                onPaste={this.handleOnPaste}
                                              />
                                            {this.state.isValidTicketNo
                                                && <span style={{ position: 'absolute', top: '25px', right: '17px', color: 'green' }}>
                                                    <i className="fas fa-check"></i> </span>}
                                            {this.state.errors["ticketNo"].length > 0 && <span className='error invalid-feedback'>
                                                {this.state.errors["ticketNo"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                           <button type="button" className="btn btn-secondary btn-sm" title="Clear"
                                            onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                                    <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                        <label className="btn-block">&nbsp;</label>
                                        <input type="checkbox" className="custom-control-input"
                                            id="isShowTicketClosedOnly"
                                            title="Show Closed Only"
                                            checked={this.state.isShowTicketClosedOnly}
                                            onChange={this.handleOnShowClosedFeedbck} />
                                        <label className="custom-control-label" style={{ paddingTop: 5 + "px", cursor: 'pointer' }}
                                            htmlFor="isShowTicketClosedOnly">Show Closed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.state.ticketGridDataSource}
                                keyExpr="id"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleOnToolbarPreparing}
                                //repaintChangesOnly={true}
                                onCellPrepared={this.handleOnCellPrepared}
                            >
                                <Column dataField="id" width={30} caption="" alignment="center" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="ticketNo" caption="Ticket No" width={'auto'} />
                                <Column dataField="locationName" caption="City" />
                                <Column dataField="userFullName" caption="User" />
                                <Column dataField="userPhone" caption="Phone" />
                                <Column dataField="userEmail" caption="Email" />
                                <Column dataField="helpTopic" caption="Help Topic" />
                                <Column dataField="priority" caption="Priority" />
                                <Column dataField="status" caption="Status" alignment="center" />
                                <Column dataField="issueSummary" caption="Issue" />
                                <Column dataField="issuedBy" caption="Issued By" />
                                <Column dataField="dateofOccurrence" caption="Occurrence Date" alignment='center' width={'auto'} />
                                <Column dataField="resolvedBy" caption="Resolved By" />
                                <Column dataField="resolutionDate" caption="Resolved Date" alignment='center' width={'auto'} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50, 100, 500]} showInfo={true} />
                                <Export enabled={false} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenAddNewTicketModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOpenNewTicketModal}
                    onRequestClose={this.handleCloseNewTicketModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isAddNewTicketSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Open a New Ticket</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseNewTicketModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Help Topic <span className="text-danger">*</span></label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select help topic"
                                            defaultValue={[]}
                                            value={this.state.helpTopicDropdownData}
                                            name="ddhelptopic"
                                            onChange={this.handleOnHelpTopicDropdownChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.helpTopicDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                        />*/}
                                        <select name="ddhelptopic" className="form-control form-control-sm"
                                            value={this.state.helpTopicId} onChange={this.handleOnInputChange}>
                                            <option value="0">---Select help topic---</option>
                                            {
                                                this.state.helpTopicDropdownList &&
                                                this.state.helpTopicDropdownList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["helpTopic"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["helpTopic"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="userFirstName"
                                            placeholder="First name"
                                            title="First Name"
                                            value={this.state.userFirstName}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["userFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userFirstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="userLastName"
                                            placeholder="Last name"
                                            title="Last Name"
                                            value={this.state.userLastName}
                                            onChange={this.handleOnInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="userEmail"
                                            placeholder="example@domain.com"
                                            title="Email"
                                            value={this.state.userEmail}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["userEmail"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userEmail"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>Code</label>
                                        <NumberFormat className="form-control form-control-sm" name="countryCode" title="Country Code" format="+##" value={this.state.countryCode} onValueChange={(e) => {
                                            let err = this.state.errors;
                                            err.countryCode = '';
                                            this.setState({ countryCode: e.value, errors: err });
                                        }} placeholder="" />
                                        {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <NumberFormat className="form-control form-control-sm" name="userMobile" title="Mobile Number"
                                            placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.userMobile}
                                            onValueChange={(e) => {
                                                let err = this.state.errors;
                                                err.userMobile = '';
                                                this.setState({ userMobile: e.value, errors: err });
                                            }} />
                                        {this.state.errors["userMobile"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userMobile"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Priority <span className="text-danger">*</span></label>
                                        <select name="ddpriority" className="form-control form-control-sm"
                                            value={this.state.priorityId} onChange={(e) => { this.setState({ priorityId: e.target.value }) }}>
                                            {
                                                priorityDropdownLis &&
                                                priorityDropdownLis.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Issue Summary <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="issueSummary"
                                            placeholder=""
                                            title="Issue Summary"
                                            value={this.state.issueSummary}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["issueSummary"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issueSummary"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Issue Details <span className="text-danger">*</span></label>
                                        <HtmlEditor
                                            height={170}
                                            name="issuedetails"
                                            value={this.state.issueDetails}
                                            valueType={"html"}
                                            onValueChanged={this.handleOnIssueBodyChange}
                                        >
                                            <Toolbar>
                                                <Item name="undo" />
                                                <Item name="redo" />
                                                <Item name="separator" />
                                                <Item
                                                    name="size"
                                                    acceptedValues={sizeValues}
                                                />
                                                <Item
                                                    name="font"
                                                    acceptedValues={fontValues}
                                                />
                                                <Item name="separator" />
                                                <Item name="bold" />
                                                <Item name="italic" />
                                                <Item name="strike" />
                                                <Item name="underline" />
                                                <Item name="separator" />
                                                <Item name="alignLeft" />
                                                <Item name="alignCenter" />
                                                <Item name="alignRight" />
                                                <Item name="alignJustify" />
                                                <Item name="separator" />
                                                <Item name="color" />
                                                <Item name="background" />
                                            </Toolbar>
                                        </HtmlEditor>
                                        {this.state.errors["issueDetails"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issueDetails"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="custom-file-upload">
                                            <button type="button"
                                                className="btn btn-secondary  btn-sm"
                                                onClick={() => { this.fileInput.click() }}
                                            ><i className="fas fa-paperclip"></i> &nbsp;Choose File</button>&nbsp;&nbsp;
                                            <span>
                                                {this.state.attachments.length === 0 ? 'No file attached' : this.state.attachments.length + ' file(s) attached'
                                                }
                                            </span>
                                            <input type="file" className="custom-file-input"
                                                ref={ref => this.fileInput = ref}
                                                name="Files" multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => { this.handleOnFileSelect(e, false) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        {
                                            this.state.attachments &&
                                            this.state.attachments.map((item, i) =>
                                                (<label style={{ marginRight: '2px' }}
                                                    key={i}>{item.fileName}
                                                <NavLink onClick={(e) => { this.removeAttachmante(e, item.id) }}>
                                                        <span style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginRight: '6px', position: 'relative', top: '-8px' }}>
                                                        ×</span></NavLink>
                                                </label>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="Save Ticket" onClick={this.handleOnSaveNewTicketSubmitted}>
                                {this.state.isAddNewTicketSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isAddNewTicketSubmitted && <span title="Save Ticket"><i className="fas fa-check"></i> Save Ticket</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearNewTicket}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Cancel"
                                onClick={this.handleCloseNewTicketModal}>
                                <i className="fas fa-times"></i> Cancel
                            </button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}
